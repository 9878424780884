<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="UndispatchedOrder"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="cdId"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1000px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!--  @change="changeTable" -->
      <!-- <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->

      <span slot="tworksite" slot-scope="text">
        <div>{{ text.wsName }}</div>
      </span>
      <span slot="cdPId" slot-scope="text, record">
        <div>{{ record.tproject ? record.tproject.pname : "" }}</div>
      </span>
      <span slot="tproject" slot-scope="text">
        {{ text ? (text.tworkerType ? text.tworkerType.wtType : "") : "" }}
      </span>

      <span slot="cdExpectStartTime" slot-scope="text, record">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>
          {{ $Format(text).slice(0, 10) }} ~
          {{ $Format(record.cdExpectEndTime).slice(0, 10) }}
        </div>
      </span>

      <span slot="cdDuration" slot-scope="text">
        <div>{{ text + "天" }}</div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf(
              'UndispatchedOrder-/api/constructionDetail/update'
            ) > -1
          "
          @click="showDrawer('AssignWorkerShow', record, '指定工人')"
          >指定</a
        >
        <a
          v-if="
            isShowBtns.indexOf(
              'UndispatchedOrder-/api/constructionDetail/update'
            ) > -1
          "
          @click="showDrawer('SendOrdersShow', record, '设置派单规则')"
          >派单</a
        >
      </span>
    </s-table>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="true"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <AssignWorker
        @onClose="onClose"
        :record="record"
        ref="AssignWorker"
        v-if="AssignWorkerShow"
        @recordChangeFn="recordChangeFn"
      />

      <SendOrders
        @onClose="onClose"
        :record="record"
        :AssignWorkerValue="AssignWorkerValue"
        ref="SendOrders"
        v-if="SendOrdersShow"
      />
      <!-- @tabledataChange="($event) => tabledataChange()" -->
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { getWorkSitelist } from "@/api/apiJF/schedule";
import { GetConstructionDetailList } from "@/api/apiJF/order";
import { GetCompanies } from "@/api/device";
import AssignWorker from "./AssignWorker.vue";
import SendOrders from "./SendOrders.vue";
import moment from "moment";
// 搜索条件
const queryItems = [
  {
    label: "项目经理",
    value: "cdWsWIdJl",
    type: "input",
    defaultValue: "",
  },
  {
    label: "分公司",
    value: "cdBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
    defaultValue: "",
  },
  {
    label: "工地名称",
    value: "cdWsName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "施工周期",
    value: "cdExpectTime",
    type: "dateRange",
    range: ["cdExpectStartTime", "cdExpectEndTime"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "施工项",
    value: "cdPName",
    type: "input",
    defaultValue: "",
  },
];
const columns = [
  {
    title: "工地",
    // width: "100px",
    scopedSlots: { customRender: "tworksite" },
    dataIndex: "tworksite",
    ellipsis: true,
    // align: "center",
  },
  {
    title: "施工项",
    width: "150px",
    dataIndex: "cdPId",
    scopedSlots: { customRender: "cdPId" },
    // align: "center",
  },
  {
    title: "所需时间",
    width: "100px",
    scopedSlots: { customRender: "cdDuration" },
    dataIndex: "cdDuration",
    align: "center",
  },
  {
    title: "施工周期",
    width: "200px",
    scopedSlots: { customRender: "cdExpectStartTime" },
    dataIndex: "cdExpectStartTime",
    align: "center",
  },
  {
    title: "工人岗位",
    width: "100px",
    dataIndex: "tproject",
    scopedSlots: { customRender: "tproject" },
    align: "center",
  },

  {
    title: "所需工人数",
    width: "100px",
    dataIndex: "cdPeople",
    align: "center",
  },
  {
    title: "指定工人情况",
    width: "110px",
    dataIndex: "cdReceiveName",
    align: "center",
  },
  {
    title: "操作",
    width: "100px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];

export default {
  name: "UndispatchedOrder",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    AssignWorker,
    SendOrders,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      DrawerTitle: "",
      visibleDrawer: false,
      // 查询参数
      queryParam: {
        cdWsWIdJl: "",
        cdBcIds: "",
        cdWsName: "",
        cdExpectStartTime: "",
        cdExpectEndTime: "",
        cdPName: "",
        cdDispatchState: "1",
        // cdState: "1",

        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: "",
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      //   pageNo: 1,
      //   pageSize: 10,
      AssignWorkerShow: false,
      SendOrdersShow: false,
      AssignWorkerValue: "",
      routeWsId: "",
    };
  },

  created() {
    this.queryItems = JSON.parse(JSON.stringify(queryItems));
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log("this.$route.params", this.$route.params);
    let params = JSON.parse(JSON.stringify(this.$route.params));
    // console.log("params", params);
    this.routeWsId = params.id;
    // if (this.$route.params) {
    if (JSON.stringify(this.$route.params) != "{}") {
      if (params.cdBcIds) {
        let params = {};
        params = JSON.parse(JSON.stringify(this.$route.params));
        // params = JSON.parse(this.$route.params);
        for (let item in params) {
          // 默认值改变
          let queryItems = JSON.parse(JSON.stringify(this.queryItems));
          queryItems.forEach((el) => {
            if (el.value == "cdExpectTime") {
              // el.defaultValue = [
              //   moment(params.cdExpectStartTime * 1000),
              //   moment(params.cdExpectStartTime * 1000),
              // ];
            } else if (el.value == item) {
              el.defaultValue = params[item];
            }
          });
          this.queryItems = queryItems;
          // 查询值的改变
          let queryParam = JSON.parse(JSON.stringify(this.queryParam));
          for (let index in queryParam) {
            if (index == item) {
              queryParam[index] = params[item];
            }
          }
          this.queryParam = queryParam;
        }
      } else {
        // this.queryItems = queryItems;
        // location.reload();
      }
      console.log(" this.queryParam ", this.queryParam);
      console.log(" this.queryItems ", this.queryItems);
    }
    this.GetCompaniesId();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.cdBcIds == "") {
            this.queryParam.cdBcIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("UndispatchedOrderColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      if (this.queryParam.cdBcIds != "") {
        let newObject = {};
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el]) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, this.queryParam);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return GetConstructionDetailList(params).then((res) => {
          // res.data.pageNo = res.data.page;
          // res.data.totalCount = res.data.sum;
          // console.log("排期列表", res);
          // return res.data;
          // let data = res;
          res.data.data.forEach((el) => {
            let cdReceiveName = [];
            el.constructionWorkers.forEach((item) => {
              cdReceiveName.push(item.tworker.wname);
            });

            el.cdReceiveName = cdReceiveName.toString();
          });

          return res.data.data ? res.data : res;
        });
      }
    },

    fetchUsers(value) {
      // this.queryParam = value;
      console.log("value", value);
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (this.queryParam.cdBcIds == "") {
        this.queryParam.cdBcIds = this.CompanyListid.toString();
      }
      this.queryParam.cdDispatchState = 1;
      this.$refs.table.refresh(true);
    },
    onClose() {
      this.visibleDrawer = false;
      this.$refs.table.refresh(true);
    },
    afterVisibleChange(val) {},
    // 指定
    showDrawer(showPage, record, name) {
      console.log(showPage, name, record);
      this.DrawerTitle = name;
      this.visibleDrawer = true;
      this.AssignWorkerShow = false;
      this.SendOrdersShow = false;
      this[showPage] = true;
      this.record = JSON.stringify(record);
    },
    // 指定工人数据改变
    recordChangeFn() {
      // this.AssignWorkerObject = JSON.parse(this.$refs.AssignWorker.newRecord);
      this.AssignWorkerValue = this.$refs.AssignWorker.newRecord;
      // console.log("recordChangeFn", this.AssignWorkerValue);
    },
  },
};
</script>
<style lang="less" scoped>
.editBtn {
  margin-right: 20px;
}
</style>